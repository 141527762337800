import {
  createElement,
  createContext,
  FC,
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
  Fragment
} from 'react';
import { BackButtonHandlerPlugin } from 'packages/back-button-handler/plugin';

interface CascadingBackHandlerContext {
  handler?: () => void;
}
const BackButtonContext = createContext<CascadingBackHandlerContext>(
  null as any
);

/*
 * This package works by using a context change changes "authorities" all the way down
 * the tree. Only the deepest most back button handler will ever be called. In addition,
 * you must only ever use a single back button handler descendent, so that there is exactly
 * 0 or 1 back button handlers per tree. This ensures that there is never more than one
 * active back button handler, but it is always the deepest handler. Each handler is called
 * and decides if it should call a child context handler, or its own prop handler.
 */

/**
 * To handle back button presses, you must wrap your react tree in a
 * `<BackButtonContextProvider />` and then use instance(s) of
 * `<BackButtonHandler />` to handle when the back button is pressed.
 */
export const BackButtonContextProvider: FC<{
  value: BackButtonHandlerPlugin;
}> = ({ children, value }) => {
  const prevContextValue = useContext(BackButtonContext);
  if (prevContextValue) {
    throw new Error('Cannot use multiple <BackButtonContextProvider />');
  }

  const contextValue = useRef<CascadingBackHandlerContext>({});
  useEffect(() => {
    const handler = (event: Event) => {
      if (!event.defaultPrevented && contextValue.current.handler) {
        contextValue.current.handler();
        event.preventDefault();
      }
    };
    value.addEventListener(handler);
    return () => value.removeEventListener(handler);
  }, [value, contextValue]);

  return (
    <BackButtonContext.Provider value={contextValue.current}>
      {children}
    </BackButtonContext.Provider>
  );
};

/**
 * Use this component to handle the hardware back button event
 *
 * NOTE: only the callback for the deepest most `<BackButtonHandler />`
 * will be called when the back button is pressed. This allows you to
 * declaratively set the handler for the back button
 *
 * Only the deepest most back button handler will ever be called. In addition,
 * you must only ever use a single back button handler descendent, so that there is exactly
 * 0 or 1 back button handlers per tree.
 */
export const BackButtonHandler: FC<{
  /**
   * The callback to be called when the back button is pressed.
   *
   * NOTE: only the callback for the deepest most `<BackButtonHandler />`
   * will be called when the back button is pressed. This allows you to
   * declaratively set the handler for the back button
   *
   * Only the deepest most back button handler will ever be called. In addition,
   * you must only ever use a single back button handler descendent, so that there is
   * exactly 0 or 1 back button handlers per tree.
   *
   * A common back button handler might be the `onClick` prop from a `useAnchor` when
   * using packages/react-nano-router
   *
   * ```ts
   * const MyComponent = () => {
   *   const anchor = useAnchor(myLink)
   *   return (
   *     <BackButtonHandler onBackButton={anchor.onClick}>
   *        <OtherJSX />
   *     </BackButtonHandler>
   *   );
   * }
   * ```
   */
  onBackButton: () => void;

  /**
   * Mark as optional to perform a noop if there has been no back button context setup.
   * Useful for usages in environments that are unaware of the running context
   */
  optional?: boolean;
}> = ({ onBackButton, children, optional = false }) => {
  const [error, setError] = useState<Error>();
  if (error) {
    throw error;
  }

  // Will not change, no context providers actually ever change the context value reference
  const prevContextValue = useContext(BackButtonContext);
  // Current value will never change, only the handler prop
  const nextContextValue = useRef<CascadingBackHandlerContext>({});

  // Save the current callback prop so we don't have to check references
  const callbackRef = useRef<() => void>(onBackButton);
  callbackRef.current = onBackButton;

  // Create a single useCallback handler
  const callback = useCallback(() => {
    const { handler = callbackRef.current } = nextContextValue.current;
    handler();
  }, [callbackRef, nextContextValue]);

  // Wait until the effect to try to take ownership
  useEffect(() => {
    if (!prevContextValue && optional) {
      return;
    }
    if (prevContextValue.handler) {
      setError(
        new Error('Cannot have multiple <BackButtonHandler /> descendants')
      );
      return;
    }
    prevContextValue.handler = callback;
    return () => {
      delete prevContextValue.handler;
    };
  }, [prevContextValue, callback, optional]);

  if (!prevContextValue) {
    if (!optional) {
      throw new Error('Missing ancestor <BackButtonContextProvider />');
    }

    return <Fragment>{children}</Fragment>;
  }

  return (
    <BackButtonContext.Provider value={nextContextValue.current}>
      {children}
    </BackButtonContext.Provider>
  );
};
